<template>
  <div>
    <LandingPageHeader/>
    <div>
      <div v-if="loading" class="tw-flex mt-n16 tw-justify-center tw-items-center tw-h-screen">
        <Loader/>
      </div>
      <div v-else>
        <v-row  @click="$router.go(-1)" no-gutters class="tw-w-10/12 tw-m-auto tw-py-12 tw-cursor-pointer" style="margin: auto!important;">
          <div class="tw-flex tw-items-center">
            <v-icon>mdi-arrow-left</v-icon>

            <span>Back to Orders</span>
          </div>
        </v-row>
        <v-row no-gutters class="tw-w-10/12 tw-m-auto tw-flex tw-flex-col-reverse md:tw-flex-row" style="margin: auto!important;">
          <v-col cols="12" md="3" sm="12">
            <div class="summary">
              <div class="tw-pb-4">
                <h3>STATUS</h3>
                <span class="tw-mt-3" :class="[order_details.status === 'Processing'? 'processing' : 'order-color' ]">{{order_details.status}}</span>
              </div>
              <v-divider />
              <div>
                <h3>ORDER NUMBER</h3>
                <h1>#{{order_details.order_id}}</h1>
              </div>
              <div>
                <h3>PLACED ON</h3>
                <!-- <h1>27 Aug, 2021, 9:01AM</h1> -->
                <h1>{{order_details.created_date}}</h1>
              </div>
              <v-divider/>
                <div>
                  <h3>SUBTOTAL</h3>
                  <h1>₦ {{order_details.price.sum_total}}</h1>
                </div>
                <div>
                  <h3>SHIPPING FEE</h3>
                  <h1>₦ {{order_details.price.shipping_fee}}</h1>
                </div>

              <div>
                <h3>TOTAL</h3>
                <h1>₦ {{getTotal(order_details.price.sum_total ,order_details.price.shipping_fee)}}</h1>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="1" sm="0" class="tw-hidden md:tw-block">

          </v-col>
          <v-col cols="12" md="8" sm="12" class="md:tw-mt-4 mt-n10 order">
            <div class="md:tw-w-9/12 tw-m-auto">
              <div v-for="(data, i) in order_details.order_items.Tees || order_details.order_items.Hoodies" :key="i">
                <div class=" tw-m-4 tw-py-10 tw-flex tw-justify-between tw-items-center">
                  <div class="tw-flex tw-items-center tw-justify-between tw-w-52">
                      <v-img
                              width="90px"
                              height="120px"
                              :src="shirt(i)"
                      />
<!--                    <img src="@/assets/shirts/kneenk-black.svg" class="tw-w-14 md:tw-w-20" height="90px"/>-->
                    <div class="tw-pr-10 md:tw-pr-0">
                      <div class="tw-flex tw-items-center">
                        <span class="dot" :style="{background: getColor(i)}"></span>
                        <h4>{{ shirtsColorAliases[getColor(i)] }}</h4>
                      </div>
                      <h4 v-for="(v, k, i) in sizes || data.sizes" :key="i" v-if="v > 0">
                        {{sizesAliases[k]}} ({{v}})
                      </h4>
                    </div>
                  </div>
                  <div class="tw-flex tw-items-center tw-justify-between md:tw-mb-0">
                    <div class="tw-flex md:tw-w-40 tw-mr-3">
                      <div class="type">
                        <h6>x{{getCount(data.sizes)}}</h6>
                      </div>
                    </div>

                  </div>
                  <h5 class="complete">₦ 3,000</h5>
                </div>
                <div>
                  <v-divider  v-if="i < order_details.order_items.Tees?.length - 1 || order_details.order_items.Hoodies?.length - 1"/>
                </div>
              </div>


              <div>
                <v-card class="card tw-p-4 tw-mb-3" outlined>
                  <div class="tw-flex md:tw-flex-row tw-flex-col tw-w-full">
                   <div class="tw-pl-4">
                     <p class="details_title">DELIVERY ADDRESS</p>
                     <p class="display_address tw-w-3/6 md:tw-w-5/6">{{ order_details.shipping_detail.address }}</p>
                   </div>
                    <div class="md:tw-pl-60 tw-pl-4 tw-pt-4 md:tw-pt-0">
                      <p class="details_title">PAYMENT METHOD</p>
                      <p class="display_address">Paystack</p>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>

          </v-col>
        </v-row>
      </div>
    </div>
  </div>

</template>

<script>
import LandingPageHeader from "@/components/LandingPageHeader";
import {getSingleOrderDetails } from "@/services/user-profile";
import Loader from "@/reuseable/Loader.vue";
import priceMixin from "@/mixins/priceMixin";
import {getFrontImage} from "@/store/buildMerch/getters";
export default {
  name: "SavedOrderDetails",
  components: {Loader, LandingPageHeader },
  mixins: [priceMixin],
  data(){
    return{
      loading: false,
        order_details:{
          price:{},
        },
      sizesAliases: {
        S: 'Small' || 'small',
        M: 'Medium' || 'medium',
        L: 'Large' || 'large',
        XL: 'XLarge' || 'extra_large',
        XXL: 'XXL' || 'extra_extra_large'
      },
        shirtsColorAliases : {
            '#000': 'Black',
            '#FFF': 'White',
            '#E80000': 'Red',
            '#FFD706': 'Yellow',
            '#CE08BA': 'Peach pink',
            '#23B100': 'Green',
            '#FF7A01': 'Orange',
            '#A4A4A4': 'Ash',
            '#2250C7': 'Blue',
            '#48A7FF': 'Sky blue',
            '#E7E7E7': 'Grey',
        }
    }
  },
  computed: {
    sizes(index) {
      const sizes = {};
      const tees = this.order_details.order_items.Tees || this.order_details.order_items.Hoodies;
      for (const tee of tees) {
        for (const [size, count] of Object.entries(tee.sizes)) {
          if (count > 0) {
            sizes[size] = count
          }
        }
        console.log(sizes)

      }
      return sizes[index];


      // const tees = this.order_details.order_items.Tees;
      // const availableSizes = {};
      //
      // for (let i = 0; i < tees.length; i++) {
      //   const sizes = tees[i].sizes;
      //
      //   for (const size in sizes) {
      //     if (sizes[size] > 0) {
      //           availableSizes[size] = sizes[size]
      //     }
      //   }
      // }
      // return availableSizes
    },

  },
  created(){
   this.getOrderDetails()
  },
  methods:{
      getColor(index) {
        return this.order_details.color[index]
      },
      shirt (index) {
        return getFrontImage(this.order_details.color[index])
    },
    getTotal(sub_total, shipping_fee){
      console.log(sub_total, shipping_fee)
      return sub_total + shipping_fee;
    },
    getCount(sizes){
      return Object.values(sizes).reduce((acc, curr) => curr + acc, 0)
    },
    getOrderDetails(){
      this.loading = true
      getSingleOrderDetails(this.$route.params.orderId)
          .then(
              res => {
                this.order_details = res.data
                console.log(res.data)
                this.loading = false
              }
          )
          .catch(
              err => {
                console.log(err)
                this.loading = false
              }
          )
    }
  }

};
</script>

<style scoped lang="scss">
.summary{

  background: #FFFFFF;
  border: 1px solid #FAFAFA;
  box-shadow: 0px 12px 24px rgba(173, 173, 173, 0.08), 0px 2px 4px rgba(173, 173, 173, 0.04);
  border-radius: 20px;
  padding: 25px;
  h1{
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #00050D;
    padding-bottom: 20px;
    //padding-top: 10px;

  }
  h3{
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  padding-top: 15px;
    color: #87827C;
  }

}
.order{
  background: #FFFFFF;
  border: 1px solid #FAFAFA;
  box-shadow: 0px 12px 24px rgba(173, 173, 173, 0.08), 0px 2px 4px rgba(173, 173, 173, 0.04);
  border-radius: 20px;
}

.dot{
  //background: #000000;
  border: 1px solid #FAFAFA;
  width: 14.22px;
  height: 14.22px;
  border-radius: 50%;
  margin-right: 4px;
}
h5{
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: right;
  color: #514A41;

}
h6{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #514A41;

}
h4{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #514A41;
}
.display_address {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: #514A41;
}
.details_title{
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #514A41;
}
.card{
  background: #FEFAF3;
  border: 0.1px solid #FF7A01;
  border-radius: 16px;
}
.order-color{
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #008F6E;
  width: 101px;
  height: 24px;
  padding: 7px 7px;
  background: #E7F8F4;
  border-radius: 4px;
}
.processing{
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #514A41;
  background: #F3F3F3;
  border-radius: 4px;
  padding: 7px;
}
</style>
